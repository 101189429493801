// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// ruvel-pereira React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ruvel-pereira React base styles
import typography from "assets/theme/base/typography";

function Footer({ }) {
  
  return (<div></div>);  
}

export default Footer;
