

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBadge from "components/MDBadge";

import AIR from "assets/images/HOLOTECH-AIR-ECOSYSTEM.svg";
import JG from "assets/images/JADEGLOBAL-LEXICAL-PARSER.svg";
import IML from "assets/images/OPENEDX-ORA-IML.svg";
import OG from "assets/images/ORGANICDROP ECOSYSTEM.svg";


// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// ruvel-pereira React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ruvel-pereira React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/profile/components/Header";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images


function Overview() {
  const profileData = profilesListData["experiences"].map((data, index) => (
    <ProfilesList
      title={data["title"] + " - " + data["company"]}
      key={data["title"] + " - " + data["company"]} // Consider using a combination or a more unique key
      profiles={data["points"]}
      shadow={false}
    />
  ));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} xl={12} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description="With over eight years of experience in dynamic software engineering roles, I am a seasoned expert specializing in object
 oriented development and DevOps. My proficiency spans crafting robust backend solutions, developing intuitive user 
interfaces, and creating dynamic web applications utilizing languages and frameworks such as JavaScript, Java, Python, 
Django, Node.js and Flask.
 I am adept at integrating AWS services, leveraging REST APIs, and managing extensive datasets. My expertise extends to 
designing scalable web applications with a steadfast focus on SOLID design principles, ensuring adherence to WCAG 
standards for accessibility.
 In addition to my technical prowess in using ReactJS, Angular, HTML5, CSS, and TypeScript, I excel in optimizing 
application performance. I lead cross-functional teams employing Agile Principles and Kanban methodologies to deliver 
high-quality software solutions. My proficiency in metadata-driven development is evident as I construct comprehensive 
test cases that utilize various testing approaches—such as automation testing, load testing, and security testing—to 
validate software functionality.
 I am also skilled in Bash scripting and Linux shell scripting, which complement my DevOps capabilities. This diverse 
technical skill set enables me to drive technical projects forward and collaborate effectively with varied teams to meet 
business objectives and foster innovative software solutions. Committed to promoting technical excellence and career 
growth, I am dedicated to mentoring team members and leading by example to achieve peak performance and meet 
stringent quality standards."
                info={{
                  fullName: "Ruvel Pereira",
                  mobile: "(+353) 899666646",
                  email: "pereiraruvel11@gmail.com",
                  location: "Ireland",
                }}
                social={[
                 
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 1 }} />
            </Grid>
            <Grid item xs={12} xl={12}>
              <h4>Highlighted Projects</h4>

             <a href={IML} rel="noreferrer" target="_blank"> <MDBadge key="badge" badgeContent="OPENEDX-ORA-IML" color="error" variant="gradient" size="lg" /></a>
             <a href={JG} rel="noreferrer" target="_blank"> <MDBadge key="badge" badgeContent="JADEGLOBAL-LEXICAL-PARSER" color="dark" variant="gradient" size="lg" /></a>
             <a href={OG} rel="noreferrer" target="_blank"> <MDBadge key="badge" badgeContent="ORGANICDROP ECOSYSTEM" color="success" variant="gradient" size="lg" /></a>
             <a href={AIR} rel="noreferrer" target="_blank"> <MDBadge key="badge" badgeContent="HOLOTECH-AIR-ECOSYSTEM" color="info" variant="gradient" size="lg" /></a>







  {profileData}
</Grid>
          </Grid>
        </MDBox>
        
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
