// ProfilesList component code block adjusted with PropTypes
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import Card from "@mui/material/Card";
import yinYang from "assets/images/yin-yang-symbol.jpg";
import './style.css';


function ProfilesList({ title, profiles, shadow }) {
  const renderProfiles = profiles.map((profile, index) => (
    <MDBox key={index} component="li" display="flex" alignItems="center" py={1} mb={1}>
      <MDBox mr={2}>
        <MDAvatar src={yinYang} alt="Profile" shadow={shadow ? "md" : "md"} />
      </MDBox>
      <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <MDTypography variant="caption" color="text">{profile}</MDTypography>
      </MDBox>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={5} px={1}>
        <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

ProfilesList.defaultProps = {
  shadow: true,
};

ProfilesList.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  shadow: PropTypes.bool,
};

export default ProfilesList;
