import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Loader from 'assets/loader.svg'; // Import your loading SVG

import React, { useState, useEffect } from 'react';

// ruvel-pereira React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// ruvel-pereira React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";

function Tables() {
  const { columns, rows } = authorsTableData();

  // Check if data is loaded
  const isDataLoaded = columns.length > 0 && rows.length > 0;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Listing
                </MDTypography>
              </MDBox>
              <MDBox pt={5} style={{ display: isDataLoaded ? 'none' : 'block' }}>
                <img src={Loader} alt="Loading..." style={{ display: 'block', margin: 'auto', width:'100px', height:'100px' }} />
              </MDBox>
              <MDBox pt={3}>
                {isDataLoaded ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
