// ruvel-pereira React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import React, { useEffect, useState } from 'react';  // Import React hooks
import Loader from 'assets/loader.svg';  // Import your loading SVG
import './authorsTableData.css';

export default function DashboardData() {
  const [projects, setProjects] = useState([]); // State to store projects data
  const [isLoading, setIsLoading] = useState(true); // State to manage loading status

  const aws_lambda_url = "https://42asxfej5g5rmhwqiuk5muudoy0qwszn.lambda-url.ap-south-1.on.aws/"
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(aws_lambda_url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setProjects(data); // Set the projects data
        setIsLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Fetching data failed:", error);
        setIsLoading(false); // Ensure loading is false on error
      }
    };

    fetchData();
  }, []);

  // Function to select a random color
  const getRandomColor = () => {
    const colors = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'light', 'dark'];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  };

  // Rendering tech stacks as individual badges
  const renderTechStack = (techStack) => (
    techStack.map((tech) => (
      <MDBadge key={tech} badgeContent={tech} color={getRandomColor()} variant="gradient" size="sm" />
    ))
  );

  // // Display loader if the data is still loading
  // if (isLoading) {
  //   return <img src={Loader} alt="Loading..." style={{ display: 'block', margin: 'auto' }} />;
  // }

  return {
    columns: [
      { Header: "Project Name", accessor: "projectName", width: "45%", align: "left" },
      { Header: "Description", accessor: "projectDescription", align: "left" },
      { Header: "Tech Stack", accessor: "techStack", align: "center" },
    ],

    rows: projects.map(project => ({
      projectName: <MDTypography variant="caption" fontWeight="medium">{project.projectName}</MDTypography>,
      projectDescription: (
        <MDBox>
          <MDTypography variant="caption" style={{ whiteSpace: 'pre-wrap', width:'40vw' }}>{project.projectDescription}</MDTypography>
        </MDBox>
      ),
      techStack: <MDBox ml={-1}>{renderTechStack(project.techStack)}</MDBox>
    })),
  };
}
