// Images


export default {
  "experiences": [
    {
      "title": "Sr. Full Stack Software Engineer",
      "company": "HOLOTECH",
      "period": "09/2022 – 05/2024",
      "location": "INDIA",
      "points": [
        "Led cross-functional teams in the development of backend solutions for Banking, Business management softwares and Distributed systems using Python, Flask/Django, Microservices, Node.js, NoSQL databases (MongoDB) and Java",
        "Implemented initiatives to optimize development processes, emphasizing Agile methodologies and utilizing CI/CD tools such as Git, Continuous Integration using Jenkins, Artifactory, and Ansible",
        "Fostered a culture of technical excellence and collaboration within the team, providing mentorship and tailored development plans for team members",
        "Designed and developed backend-centric applications for business management software, improving automation and feedback processes",
        "Developed custom frameworks to enhance scalability and efficiency of backend operations",
        "Engineered robust test cases and utilized diverse testing methodologies, including automation, load, and security testing, to ensure software functionality",
        "Implemented advanced SEO strategies for clients across diverse industries to boost online visibility and organic traffic",
        "With a strong focus on DevOps practices, I utilized both AWS and Google Cloud Platform to streamline deployment processes and enhance collaboration across development teams. My efforts were crucial in automating workflows, implementing continuous integration and deployment pipelines, and ensuring high availability and disaster recovery strategies.",
        "Collaborated with cross-functional teams to gather requirements and translate them into technical specifications, utilizing CI/CD tools effectively",
        "Led the development of management systems for businesses, optimizing backend operations and user experiences using Python, Java, ReactJS, Typescript, Angular, HTML5, CSS, and JavaScript",
        "Conducted research on machine learning applications, including music genre classification and skin disease prediction in Python and React ecosystem",
        "Ensured software compliance with functional and non-functional requirements, leveraging a strong understanding of software testing methodologies",
        "Enhanced application performance by identifying and resolving bottlenecks and implementing efficient algorithms to optimize operations."
      ]
    },
    {
      "title": "Python - Data Engineer",
      "company": "Jade Global",
      "period": "12/2021 – 09/2022",
      "location": "INDIA · REMOTE",
      "points": [
        "Led a critical migration project from SAP HANA to Snowflake, enhancing the company's data warehousing capabilities and integrating the Pandas library for advanced data manipulation and reporting.",
        "Optimized backend database operations through Snowflake and SQL, resulting in streamlined data management processes",
        "Designed and deployed scalable backend database solutions, effectively managing projects in Agile environments",
        "Collaborated on the development of Flask/Django-based applications to streamline backend operations and enhance data integrity also utilized PyCharm and Eclipse for robust development processes",
        "Implemented advanced database technologies to support key business processes, demonstrating expertise in data engineering and Python integrations",
        "Integrated AWS services and REST APIs to ensure robust and secure data handling"
      ]
    },
    {
      "title": "Full Stack Software Engineer",
      "company": "OrganicDrop",
      "period": "11/2020 – 11/2021",
      "location": "INDIA · REMOTE",
      "points": [
        "Directed the development of an innovative e-commerce platform, integrating Python backend solutions with JavaScript enhanced frontends",
        "Developed and managed the high-performance mobile application to enhance user engagement",
        "Spearheaded the architecture and management of a sophisticated Point of Sale (POS) system customized for multi-store retail chains to improve operational efficiency and customer experience",
        "Developed seamless integration across platforms using FastAPI, Java, Python, Django, Node.js and React",
        "Developed comprehensive test cases and applied diverse software testing techniques, including automation testing, regression testing, and system testing, to enhance project outcomes",
        "Served as Product Manager for OrganicDrop, an innovative e-commerce platform",
        "Leveraged AWS and Google Cloud Platform to advance DevOps practices, streamline deployment processes, and enhance team collaboration",
        "Led the backend development of OrganicDrop’s e-commerce application, resulting in a 300% increase in online transactions year-over-year",
        "Led the development of backend solutions utilizing Python, Flask/Django, and integrated ReactJS and JavaScript for frontend enhancements",
        "Integrated inventory management software services into the platform",
        "Implemented performance optimization techniques such as profiling, caching (Redis), and code refactoring",
        "Constructed and deployed microservices in Python to improve system scalability and reliability",
        "Provided technical leadership and mentorship to junior developers to cultivate a collaborative and knowledge-sharing environment."
      ]
    },
    {
      "title": "Full Stack Software Engineer",
      "company": "Unbox Innovations",
      "period": "01/2019 – 10/2020",
      "location": "INDIA · ON-SITE",
      "points": [
        "Integrated IML API with OpenEdX ORA XBlock utilizing core Python, Flask/Django, Node.js and DevOps practices",
        "Developed solutions for government school management and international exam preparation platforms",
        "Enhanced backend operations for e-commerce applications by integrating UI components using Flutter",
        "Collaborated with UX/UI designers to translate designs into code in HTML5, CSS, React and Vue.js for optimal user experience",
        "Conducted unit testing and end-to-end testing to guarantee quality and functionality of developed solutions",
        "Proficiently utilized CI/CD tools and Agile project management methodologies in project execution, managing version control through GitHub"
      ]
    },
    {
      "title": "Full Stack Software Engineer",
      "company": "G10 Consultancy",
      "period": "01/2019 – 10/2020",
      "location": "INDIA",
      "points": [
        "Led backend-focused projects using Android, HTML5, CSS, React, Angular, and Java technologies",
        "Developed extensive full-stack skills with a focus on backend technologies like Node.js, PHP, Django, and Flask",
        "Demonstrated strong project management and team leadership abilities in Agile settings",
        "Managed SQL Relational databases and NoSQL databases (MongoDB) to improve backend development efficiency",
        "Implemented efficient algorithms and optimized code for enhanced backend performance",
        "Proficient in testing frameworks like Selenium and Pytest for Python applications",
        "Implemented multithreading and concurrency in the Catering App to optimize performance and efficiency",
        "Experienced in Linux/UNIX environments to improve system performance and reliability"
      ]
    }
  ]
}



// export default [
//   {
//     image: yinYang,
//     name: "",
//     description: "Led cross-functional teams in the development of backend solutions for Banking, Business management softwares and Distributed systems using Python, Flask/Django, Microservices, Node.js, NoSQL databases (MongoDB) and Java",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "",
//     },
//   },
//   {
//     image: yinYang,
//     name: "",
//     description: "Implemented initiatives to optimize development processes, emphasizing Agile methodologies and utilizing CI/CD tools such as Git, Continuous Integration using Jenkins, Artifactory, and Ansible",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "",
//     },
//   },
//   {
//     image: yinYang,
//     name: "",
//     description: "Fostered a culture of technical excellence and collaboration within the team, providing mentorship and tailored development plans for team members",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "",
//     },
//   },
//   {
//     image: yinYang,
//     name: "",
//     description: "Designed and developed backend-centric applications for business management software, improving automation and feedback processes",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "",
//     },
//   },
//   {
//     image: yinYang,
//     name: "",
//     description: "Engineered robust test cases and utilized diverse testing methodologies, including automation, load, and security testing, to ensure software functionality",
//     action: {
//       type: "internal",
//       route: "/pages/profile/profile-overview",
//       color: "info",
//       label: "",
//     },
//   },
// ];
